import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './pages/NotFound'; // Import your NotFound component


function App() {
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000,
            easing: 'ease-out-cubic',
        });
    }, []);

    useDocTitle("Taab4 Company");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/get-demo" element={<DemoProduct />} />  */}
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
