import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  const styles = {
    page404: {
      padding: '0',
      background: '#908f8f',
      fontFamily: "'Arvo', serif",
      width: '100%', // Ensure the section takes the full width
      height: '120vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fourZeroFourBg: {
      backgroundImage: 'url("https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif")',
      height: '400px',
      backgroundPosition: 'center',
    },
    fourZeroFourBgText: {
      fontSize: '80px',
    },
    contantBox404: {
      marginTop: '-50px',
    },
    link404: {
      color: '#fff',
      padding: '10px 20px',
      background: '#000',
      margin: '20px 0',
      display: 'inline-block',
      borderRadius: '5px',
    },
  };

  return (
    <section style={styles.page404}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div style={styles.fourZeroFourBg}>
                <h1 className="text-center" style={styles.fourZeroFourBgText}>
                  404
                </h1>
              </div>
              <div style={styles.contantBox404}>
                <h3 className="h2">Look like you're lost</h3>
                <p>The page you are looking for is not available!</p>
                <Link to="/" style={styles.link404}>
                  Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
