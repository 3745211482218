import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navbar/NavLinks';
import Logo from '../../images/logo-normal.jpeg';
import ContactUs from '../ContactUs'; // Import the modal

const NavBar = () => {
    const [top, setTop] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isContactOpen, setContactOpen] = useState(false); // Manage modal state here
    const mobileMenuRef = useRef(null); // Ref for the mobile menu

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const toggleContactDialog = () => {
        setContactOpen(!isContactOpen); // Toggle the contact modal state
        closeMenu(); // Close the menu when opening the modal
    };

    const handleClickOutside = (event) => {
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            closeMenu(); // Close the menu if clicked outside
        }
    };

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);

        // Add event listener for clicks outside the menu
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [top, isOpen]);

    return (
        <>
            <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${!top && 'bg-white shadow-lg'}`}>
                <div className="flex flex-row justify-between items-center px-6 w-full sm:px-6 max-md:py-2">
                    <div className="flex items-center space-x-2 sm:space-x-4 w-full justify-start pl-2 sm:pl-10">
                        <Link to="/" className="overflow-hidden p-0 flex justify-center transition-all ease-in-out hover:opacity-100 rounded-2xl">
                            <img src={Logo} alt="client" style={{ height: '3rem', width: 'auto' }} className="object-contain" />
                        </Link>
                        <Link to="/" className="text-black ml-2 sm:ml-4">
                            <h1 className="font-extrabold text-xl sm:text-4xl whitespace-nowrap" style={{ fontFamily: "'Pacifico', cursive", fontWeight: 700 }}>TaaB4</h1>
                        </Link>
                    </div>

                    <div className="flex items-center lg:hidden">
                        <button className="p-2 rounded-lg text-black" onClick={handleClick}>
                            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                {isOpen ? (
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                                ) : (
                                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                )}
                            </svg>
                        </button>
                    </div>

                    <div className="hidden space-x-6 lg:inline-block p-5 me-7">
                        <NavLinks onLinkClick={closeMenu} toggleContactDialog={toggleContactDialog} />
                    </div>
                </div>

                {/* Mobile menu (NavLinks) */}
                {isOpen && (
                    <div ref={mobileMenuRef} className="lg:hidden flex flex-col space-y-4 p-6 bg-white shadow-lg absolute top-0 left-0 w-full z-40 justify-center items-center">
                        <NavLinks onLinkClick={closeMenu} toggleContactDialog={toggleContactDialog} />
                    </div>
                )}
            </nav>

            {/* Conditional rendering of ContactUs modal */}
            {isContactOpen && <ContactUs isOpen={isContactOpen} toggleDialog={toggleContactDialog} />}
        </>
    );
};

export default NavBar;
