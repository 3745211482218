    import React from 'react';
    import Slider from 'react-slick';
    import nahwand from '../images/nahwand.jpeg';
    import food from '../images/foodWorld.jpeg';
    import plugStore from '../images/plugStore.jpeg';
    import news from '../images/news.jpeg';
    import "slick-carousel/slick/slick.css";
    import "slick-carousel/slick/slick-theme.css";
    import { FaArrowRight } from "react-icons/fa";
    import { FaArrowLeft } from "react-icons/fa";

    const clientImage = {
        height: '20rem',
        width: '100%',
        objectFit: 'contain',
        mixBlendMode: 'colorBurn'
    };

    const clients = [
        { title: "Food World", imgSrc: food, link: "https://food-world-3dfd.vercel.app/ar" },
        { title: "Nahwand BBQ Restorant", imgSrc: nahwand, link: "https://www.nahawandbbq.com/menu" },
        { title: "Plug Store", imgSrc: plugStore, link: "https://plugstoredemo.netlify.app/" },
        { title: "Alnushama", imgSrc: news, link: "https://alnushama.com/mohammad02/wordpress/" }
    ];

    // Custom arrow components with increased icon size
    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                className="absolute right-0 transform -translate-y-1/2 z-10 cursor-pointer text-gray-500 hover:text-black"
                onClick={onClick}
            >
                <FaArrowRight size={32} />
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                className="absolute left-0 bottom-0  transform -translate-y-1/2 z-10 cursor-pointer text-gray-500 hover:text-black"
                onClick={onClick}
            >
                <FaArrowLeft size={32} />
            </div>
        );
    };

    const Clients = () => {
        const sliderSettings = {
            dots: true,
            dotsClass: "slick-dots custom-dots", // Custom class for dots
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: false,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div className="mt-14 bg-gray-100 relative">
                <section data-aos="fade-up">
                    <div className="p-14" data-aos="fade-in" data-aos-delay="600">
                        <h3 className="text-3xl text-black font-bold text-center mt-14">
                            <span className="font-black">Clients</span>
                        </h3>
                        <div className="relative">
                            <Slider {...sliderSettings} className="mt-8">
                                {clients.map((client, index) => (
                                    <div key={index} className="text-center px-4">
                                        <a
                                            href={client.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="overflow-hidden flex justify-center transition-all ease-in-out hover:opacity-80"
                                        >
                                            <img style={clientImage} src={client.imgSrc} alt={client.title} className='rounded-2xl' />
                                        </a>
                                        <div className="text-lg font-semibold mb-2">{client.title}</div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </section>

                {/* Add custom CSS for arrows and dots */}
                <style jsx>{`
                    .slick-dots {
                        position: relative;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }

                    .slick-dots li button:before {
                        font-size: 14px;
                        color: #bbb; /* Color of the dots */
                    }

                    .slick-dots li.slick-active button:before {
                        color: #333; /* Active dot color */
                    }

                    .slick-prev, .slick-next {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-80%);
                        z-index: 10;
                        font-size: 32px; /* Arrow size */
                        color: #333;
                    }

                    .slick-prev {
                        left: 0;
                        top: 0;
                    }

                    .slick-next {
                        right: 0;
                    }

                    .custom-dots {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        z-index: 1;
                    }
                `}</style>
            </div>
        );
    }

    export default Clients;
