import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Services from '../components/Services';

const Home = () => {
    return (
        <>
            <div id="hero">
                <Hero />
            </div>
            <div id="about">
                <Intro /> {/* Make sure this ID matches */}
            </div>
            {/* <div id="services">
                <Portfolio />
            </div> */}
       
     
            <div id="services">
                <Services />  
            </div>
            <div id="clients">
                <Clients />
            </div>
            <Cta />
            <Footer />
        </>
    );
}

export default Home;
