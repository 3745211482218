import React from 'react';
import { Link } from 'react-router-dom';

const NavLinks = ({ onLinkClick, toggleContactDialog }) => {
    const linkStyle = {
        padding: '0.5rem 1rem',
        fontWeight: '800',
        color: '#4B5563',
        textDecoration: 'none',
        // transition: 'color 0.3s ease',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    };

    const handleScroll = (event, targetId) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        onLinkClick(); // Close the menu when a link is clicked
    };

    return (
        <nav className="flex flex-col lg:flex-row items-start nowrap">
            <Link style={linkStyle} to="/" onClick={onLinkClick}>
                Home
            </Link>
            <Link style={linkStyle} to="/#about" onClick={(e) => handleScroll(e, "about")}>
                About US
            </Link>
            <Link style={linkStyle} to="/#services" onClick={(e) => handleScroll(e, "services")}>
                Services
            </Link>
            <Link style={linkStyle} to="/#portfolio" onClick={(e) => handleScroll(e, "clients")}>
                Clients
            </Link>
            <span style={linkStyle} onClick={toggleContactDialog}>
                Contact US
            </span>
        </nav>
    );
};

export default NavLinks;
