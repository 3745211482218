import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/Taab4-hero.svg';
import { Helmet } from 'react-helmet';

const Hero = () => {
    const [hideSvg, setHideSvg] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setHideSvg(width <= 1620 && width >= 1012);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Helmet>
                <title>Software Service Company - TaaB4</title>
                <meta name="description" content="We turn innovative ideas into powerful software solutions." />
            </Helmet>
            <header className="hero mt-12" id="hero">
                <NavBar />
                <div className="m-auto overflow-hidden mx-4 lg:mt-6 p-2 md:p-12 h-auto mb-12">
                    <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center mt-16 mb-8 lg:mb-0" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-7 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-black">
                                Software Service Company <br />
                                That Turns Innovative Ideas <br />
                                Into Powerful Software Solutions
                            </h1>
                            <p className="mb-8 text-sm sm:text-base md:text-lg text-gray-600 font-semibold">
                                Software Development | Web Development | Web Design | Mobile Application | Consulting
                            </p>
                        </div>
                        <div className="flex justify-center w-full lg:w-1/2 mt-3" data-aos="fade-up" data-aos-delay="700">
                            <img alt="hero img" className="duration-1000 w-full md:w-[80%] lg:w-[70%] rounded-2xl" src={heroImg} />
                        </div>
                    </div>
                </div>

                {/* Conditionally hide the SVG */}
                {!hideSvg && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 10 1440 180"
                        className="w-full mt-12 lg:mt-0 lg:absolute lg:bottom-0"
                    >
                        <path
                            fill="#908f8f"
                            fillOpacity="1"
                            d="M0,64L48,74.7C96,85,192,107,288,117.3C384,128,480,128,576,117.3C672,107,768,85,864,96C960,107,1056,149,1152,160C1248,171,1344,160,1392,160L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                        />
                    </svg>
                )}
            </header>
        </>
    );
};

export default Hero;
